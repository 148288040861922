import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'shadcn-components/ui/button'
import { Label } from 'shadcn-components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import { inputFieldChange } from 'services/Utils'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import { InfoIcon } from 'lucide-react'
import MultiSelect from 'shadcn-components/ui/multiselect'

const VideoMedia = ({
  brands,
  products,
  failedSpec,
  video,
  mediaUpdate,
  uploadingMedia,
  updatingMedia,
  failedSpecUpdate,
  productsDataObject,
}) => {
  const [brand, setBrand] = useState()
  const [brandValidation, setBrandValidation] = useState('')
  const [brandFocus, setBrandFocus] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedProductsValidation, setSelectedProductsValidation] =
    useState('')
  const [selectedProductsFocus, setSelectedProductsFocus] = useState('')
  const [videoFile, setVideoFile] = useState(null)
  const [videoFileValidation, setVideoFileValidation] = useState('')
  const [videoValidationErrors, setVideoValidationErrors] = useState([
    'Please upload a video.',
  ])
  const history = useHistory()
  const hasMediaId = video?.media_id || video?.creative_asset_id

  useEffect(() => {
    if (hasMediaId) {
      setBrand({ value: video.brand_id, label: video.brand })
      setBrandValidation('has-success')
      setSelectedProducts(
        video.product_asins.map((asin) => ({ value: asin, label: asin }))
      )
      setSelectedProductsValidation(
        video.product_asins.length > 0 ? 'has-success' : ''
      )
      setVideoFileValidation('has-success')
      failedSpecUpdate()
    }
  }, [video])

  const onUpload = (file, videoMeta) => {
    setVideoFile(file)
    setVideoFileValidation(validateVideo(file, videoMeta))
    failedSpecUpdate()
  }

  const validateVideo = (file, videoMeta) => {
    let errors = []
    if (file) {
      if (file.size / 1024 / 1024 > 500) {
        errors.push('Video must be 500 MB or smaller,')
      }
      if (videoMeta.duration < 6.0 || videoMeta.duration > 46.0) {
        errors.push('Video must be between 6 and 45 seconds long,')
      }
      if (
        file.type !== 'video/quicktime' && // can't check dimension of quicktime/mov files
        ([1280, 1920, 3840].indexOf(videoMeta.videoWidth) === -1 ||
          [1280, 1920, 3840].indexOf(videoMeta.videoWidth) !==
            [720, 1080, 2160].indexOf(videoMeta.videoHeight))
      ) {
        errors.push(
          'Video dimensions must be 1280 x 720px, 1920 x 1080px or 3840 x 2160px'
        )
      }
    }
    setVideoValidationErrors(errors)
    return errors.length > 0 ? 'has-danger' : 'has-success'
  }

  const saveVideoClick = (e) => {
    if (
      brandValidation === 'has-success' &&
      selectedProducts.length > 0 &&
      videoFileValidation === 'has-success'
    ) {
      mediaUpdate({
        brand_entity_id: brand?.value,
        action: 'upload_brand_media',
        product_asins: selectedProducts?.map((p) => p.value),
        file: videoFile,
      })
    } else {
      if (brand?.value === '') {
        setBrandValidation('has-danger')
      }
      if (selectedProducts?.length === 0) {
        setSelectedProductsValidation('has-danger')
      }
      if (videoFile === null) {
        setVideoFileValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const updateVideoClick = (e) => {
    if (brandValidation === 'has-success' && selectedProducts.length > 0) {
      mediaUpdate({
        brand_entity_id: brand?.value,
        action: 'update_brand_media',
        media_id: video?.media_id,
        creative_asset_id: video?.creative_asset_id,
        product_asins: selectedProducts?.map((p) => p.value),
      })
    } else {
      if (brand?.value === '') {
        setBrandValidation('has-danger')
      }
      if (selectedProducts?.length === 0) {
        setSelectedProductsValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const handleSelect = useCallback((option) => {
    setSelectedProducts((prev) => [...prev, option])
  }, [])

  const handleUnselect = useCallback((option) => {
    setSelectedProducts((prev) => prev.filter((p) => p.value !== option.value))
  }, [])

  const getCustomFilteredOptions = useCallback(
    (inputValue) => {
      if (!inputValue) {
        return products.filter(
          (product) =>
            !selectedProducts.some(
              (selected) => selected.value === product.value
            )
        )
      }

      const productsArray = Array.isArray(productsDataObject)
        ? productsDataObject
        : Object.values(productsDataObject)
      const searchTerm = inputValue.toLowerCase().trim()

      const filtered = products.filter((product) => {
        if (
          selectedProducts.some((selected) => selected.value === product.value)
        ) {
          return false
        }

        const matchingProduct = productsArray.find(
          (p) => p.asin === product.value
        )
        if (!matchingProduct) return false

        return (
          product.value.toLowerCase().includes(searchTerm) ||
          (matchingProduct.sku?.toLowerCase() || '').includes(searchTerm) ||
          (matchingProduct.parent_asin?.toLowerCase() || '').includes(
            searchTerm
          ) ||
          (matchingProduct.product_name?.toLowerCase() || '').includes(
            searchTerm
          )
        )
      })

      return filtered
    },
    [products, productsDataObject, selectedProducts]
  )

  return (
    <>
      {!hasMediaId || (products?.length > 0 && brand) ? (
        <form className=" space-y-4 w-full md:w-[650px]">
          <div className="flex flex-col gap-1">
            <Label htmlFor="brand" className="flex items-center">
              Brand
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <InfoIcon className="ml-2 h-4 w-4" />
                  </TooltipTrigger>
                  <TooltipContent>
                    Your brand must be enrolled in Amazon Brand Registry for it
                    to appear here.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <Select
              value={brand?.value}
              disabled={hasMediaId}
              onValueChange={(value) => {
                const selectedBrandOption = brands.find(
                  (b) => b?.brand_entity_id === value
                )
                const selectedOption = {
                  value: selectedBrandOption?.brand_entity_id,
                  label: selectedBrandOption?.brand_registry_name,
                }
                inputFieldChange(
                  selectedOption,
                  setBrand,
                  setBrandValidation,
                  'select_length',
                  1
                )
              }}
            >
              <SelectTrigger id="brand" className={brandValidation}>
                <SelectValue placeholder="Select a brand" />
              </SelectTrigger>
              <SelectContent>
                {brands.map((brand) => (
                  <SelectItem
                    key={brand.brand_entity_id}
                    value={brand.brand_entity_id}
                  >
                    {brand.brand_registry_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {brandValidation === 'has-danger' && (
              <p className="text-sm text-red-400 mt-1">Brand is required</p>
            )}
          </div>

          {brand?.value && (
            <>
              <div className="flex flex-col gap-1">
                <Label htmlFor="products" className="flex items-center">
                  Associated Products
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <InfoIcon className="ml-2 h-4 w-4" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Associate one or more product ASINs with this video.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>

                <MultiSelect
                  options={products}
                  selectedOptions={selectedProducts}
                  onOptionSelect={handleSelect}
                  onOptionUnselect={handleUnselect}
                  placeholder="Search by product name, ASIN, Parent ASIN or SKU..."
                  filteredOptions={(inputValue) =>
                    getCustomFilteredOptions(inputValue)
                  }
                />
                {selectedProducts.length === 0 && (
                  <p className="text-sm text-red-400 mt-1">
                    At least one product is required
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <Label htmlFor="video" className="flex items-center">
                  Video
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <InfoIcon className="ml-2 h-4 w-4" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <strong>Video requirements</strong>
                        <br />
                        - 6-45 sec long
                        <br />
                        - 16:9 aspect ratio
                        <br />
                        - 1280 x 720px, 1920 x 1080px or 3840 x 2160px
                        <br />
                        - 23.976, 24, 25, 29.97, 29.98, or 30 fps
                        <br />
                        - 1 Mbps or higher bit rate
                        <br />
                        - H.264 or H.265 codec
                        <br />
                        - 500 MB or smaller
                        <br />
                        - MP4 or MOV file
                        <br />- Audio bit rate of 96 kbps or higher
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Label>
                <UploadThumbnailGallery
                  assets={[]}
                  assetType={'video'}
                  onPick={(e) => e.preventDefault()}
                  onUpload={onUpload}
                  uploading={uploadingMedia}
                  videoURL={
                    hasMediaId ? video.published_media_url || 'pending' : ''
                  }
                />
                {videoFileValidation === 'has-danger' && (
                  <ul className="list-disc pl-5 text-sm text-red-400 mt-2">
                    {videoValidationErrors.map((error, idx) => (
                      <li key={idx}>{error}</li>
                    ))}
                  </ul>
                )}
                {failedSpec && (
                  <ul className="list-disc pl-5 text-sm text-red-400 mt-2">
                    {failedSpec.map((error, idx) => (
                      <li key={idx}>
                        Error on {error.type}. Valid values [
                        {error.expectedValues.join(', ')}]. Current value [
                        {error.value}]
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </>
          )}

          <div className="flex space-x-4">
            <Button
              type="submit"
              disabled={
                uploadingMedia ||
                updatingMedia ||
                brandValidation !== 'has-success' ||
                selectedProducts.length === 0 ||
                (!hasMediaId && videoFileValidation !== 'has-success')
              }
              className=" min-w-24"
              onClick={(e) =>
                hasMediaId ? updateVideoClick(e) : saveVideoClick(e)
              }
            >
              {uploadingMedia || updatingMedia ? (
                <LoadingSpinner className="mr-2 h-4 w-4" />
              ) : hasMediaId ? (
                'Update'
              ) : (
                'Save Video'
              )}
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => history.push('/admin/creatives/video')}
              className="min-w-24"
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : null}
    </>
  )
}

export default VideoMedia
