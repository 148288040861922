import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Papa from 'papaparse'
import { AlertCircle, Check, FileQuestion, HelpCircle } from 'lucide-react'

import { Button } from 'shadcn-components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import { Badge } from 'shadcn-components/ui/badge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import { Alert, AlertDescription } from 'shadcn-components/ui/alert'

import { inputFieldChange } from 'services/Utils'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import { useQueryClient } from 'react-query'
import apiProducts from 'services/api/Product'
import { sortByField, uniqueListOfObjects } from 'services/Utils'
import { LoadingSpinner } from 'shadcn-components/ui/spinner'
import CustomTable from 'components/CustomTable/CustomTable'
import { useToast } from 'shadcn-components/ui/use-toast'

const ProductsImport = ({ seller, importType, setImportType, importTypes }) => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const [type, setType] = useState(
    importTypes.find((t) => t.options.value === importType)?.options
  )
  const [typeHeaders, setTypeHeaders] = useState(
    importTypes.find((t) => t.options.value === importType)?.fields
  )
  const [typeValidation, setTypeValidation] = useState('')
  const [typeFocus, setTypeFocus] = useState(false)

  const [importFile, setImportFile] = useState(null)
  const [importFileValidation, setImportFileValidation] = useState('')
  const [importValidationErrors, setImportValidationErrors] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [isImporting, setIsImporting] = useState(false)

  const [parsedData, setParsedData] = useState([])
  const [parsedErrors, setParsedErrors] = useState([])
  const [previewTableData, setPreviewTableData] = useState([])
  const [previewTableHeaders, setPreviewTableHeaders] = useState([])

  const previewRowCount = 3

  const handleTypeChange = (selectedType) => {
    setImportType(selectedType.value)
    setTypeHeaders(
      importTypes.find((t) => t.options.value === selectedType.value)?.fields
    )
    setParsedData([])
    setParsedErrors([])
    setPreviewTableData([])
    setPreviewTableHeaders([])
    setImportFile(null)
    setImportFileValidation('')
    setImportValidationErrors([])
  }

  const createTargetData = (type) => {
    const baseData = {
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
    }

    if (type === 'targets') {
      return {
        ...baseData,
        targets: parsedData.map((object) => ({
          ...object,
          state: 'enabled',
        })),
      }
    }

    if (type === 'costs') {
      return {
        ...baseData,
        costs: parsedData.map((object) => ({
          ...object,
          effective_from: new Date(),
        })),
      }
    }
  }

  const createApiCall = async (type, data) => {
    if (type === 'targets') {
      return await apiProducts.createBulkTargets(data)
    }

    if (type === 'costs') {
      return await apiProducts.createBulkCosts(data)
    }
  }

  const importDataClick = async (e) => {
    if (!importFile) {
      setImportFileValidation('has-danger')
      setImportValidationErrors(['Please first upload a CSV file.'])
      return
    }

    if (importFileValidation !== 'has-success') {
      return
    }

    setIsImporting(true)

    const targetData = createTargetData(importType)
    const response = await createApiCall(importType, targetData)

    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      toast({ variant: 'success', description: response?.data?.message })
      setImportType(null)
    } else if (response) {
      toast({
        variant: 'destructive',
        description: response?.data?.message,
      })
    }

    setIsImporting(false)
  }

  const onUpload = (file) => {
    setImportFile(file)
    setImportFileValidation(validateFile(file))
  }

  const validateFile = (file) => {
    let errors = []
    if (file) {
      if (file.size / 1024 / 1024 > 100) {
        errors.push('File must be 100 MB or smaller,')
      }
      if (file.type !== 'text/csv') {
        errors.push('File must be of CSV format')
      }
    }
    setImportValidationErrors(errors)
    return errors.length > 0 ? 'has-danger' : 'has-success'
  }

  useEffect(() => {
    if (importFile && importFileValidation === 'has-success') {
      setIsUploading(true)
      Papa.parse(importFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const headers = []
          // filter columns to only include headers for importType
          const [data, rowsRemoved] = parsedDataValidationAndFormatting(
            results?.data,
            typeHeaders
          )
          if (
            data?.length > 0 &&
            Object.keys(data[0])?.length === typeHeaders?.length
          ) {
            const previewData = data?.slice(0, previewRowCount)
            previewData.map((d) => {
              headers.push(Object.keys(d))
            })
            setParsedData(data)
            setPreviewTableData(data?.slice(0, previewRowCount))
            setPreviewTableHeaders(headers[0])
            if (rowsRemoved.length > 0) {
              setParsedErrors([
                {
                  message:
                    'Warning: row(s) ' +
                    rowsRemoved.join(', ') +
                    ' were removed due to invalid or duplicate data',
                },
              ])
            } else {
              setParsedErrors(results.errors.slice(0, 10))
            }
          } else {
            setImportFileValidation('has-danger')
            setImportValidationErrors([
              'Error: expecting data for the fields ' + typeHeaders.join(', '),
            ])
          }
          setIsUploading(false)
        },
      })
    }
  }, [importFile, importFileValidation, typeHeaders])

  const parsedDataValidationAndFormatting = (data, headers) => {
    if (headers.includes('match_type')) {
      // filter columns to only include headers for importType
      const d = data?.map((row) =>
        headers
          ?.filter((key) => key in row)
          ?.reduce((obj, key) => ((obj[key] = row[key]), obj), {})
      )
      // format data
      const d1 = d.map((r, i) => ({
        ...r,
        ...(r.asin && { asin: r.asin?.toUpperCase().trim() }),
        ...(r.type && { type: r.type?.toLowerCase().trim() }),
        ...(r.match_type &&
          r.type === 'keyword' && {
            match_type: r.match_type?.toLowerCase().trim(),
          }),
        ...(!r.match_type &&
          r.type === 'product' && { match_type: 'asinSameAs' }),
        ...(!r.match_type &&
          r.type === 'category' && { match_type: 'asinCategorySameAs' }),
        ...(r.target && {
          target:
            r.type?.toLowerCase().trim() === 'product'
              ? r.target?.toUpperCase().trim()
              : r.target
                  ?.toLowerCase()
                  .split(' ')
                  .map((w) => w?.trim())
                  .filter((w) => w !== '')
                  .join(' '),
        }),
        row: i + 2,
      }))
      // general filters
      const d2 = d1.filter(
        (r) =>
          r?.asin !== '' &&
          ['keyword', 'product', 'category'].includes(r?.type) &&
          r?.target !== ''
      )
      // target type specific filters
      const dK = d2
        .filter((r) => r?.type === 'keyword')
        .filter((r) => ['exact', 'phrase', 'broad'].includes(r?.match_type))
      const dP = d2
        .filter((r) => r?.type === 'product')
        .filter((r) => ['asinSameAs'].includes(r?.match_type))
      const dC = d2
        .filter((r) => r?.type === 'category')
        .filter((r) => r?.match_type?.includes('asinCategorySameAs'))
      const d3 = [...dK, ...dP, ...dC].sort(sortByField('row'))
      // remove duplicates
      const d4 = uniqueListOfObjects(d3, headers)
      // determine rows removed
      const rowsRemoved = d1
        .map((r) => r.row)
        .filter((x) => !d4.map((r) => r.row).includes(x))
      // remove row key
      const d5 = d4.map(({ row, ...r }) => r)

      // sort match_type to expected order for grouping
      const d6 = d5
        .slice()
        .sort(
          (a, b) =>
            ['exact', 'phrase', 'broad', ''].indexOf(a.match_type) -
            ['exact', 'phrase', 'broad', ''].indexOf(b.match_type)
        )
      // group match_types for same asin, type, target combo
      const d7 = [
        ...d6
          .reduce((r, o) => {
            const key = o.asin + '-' + o.type + '-' + o.target
            const item =
              r.get(key) ||
              Object.assign({}, o, {
                match_type: '',
              })
            item.match_type +=
              item.match_type === '' ? o.match_type : ',' + o.match_type
            return r.set(key, item)
          }, new Map())
          .values(),
      ]

      return [d7, rowsRemoved]
    } else {
      // convert any non-numeric strings or nulls to zero for cost fields
      const d1 = data?.map((row) =>
        headers
          ?.filter((key) => key in row)
          ?.reduce((obj, key) => {
            if (
              [
                'cost_of_goods_per_unit',
                'shipping_cost_per_unit',
                'misc_cost_per_unit',
              ].includes(key)
            ) {
              obj[key] =
                isNaN(row[key]) || row[key] === null || row[key] === ''
                  ? 0
                  : parseFloat(row[key])
            } else {
              obj[key] = row[key]
            }
            return obj
          }, {})
      )
      // Filter out rows with mismatched headers
      const d2 = d1.filter(
        (r) =>
          Object.keys(r).length === headers.length &&
          Object.keys(r).every((key) => headers.includes(key))
      )
      // Get unique list based on 'asin'
      const d3 = uniqueListOfObjects(d2, ['asin'])
      // Create a set to hold the stringified version of objects from d3 for quick look-up.
      const d3Set = new Set(d3.map(JSON.stringify))
      // Create an array to hold the index of the rows that existed in d1 but not in d3.
      const rowsRemoved = []
      // Loop through d1 and identify which rows don't exist in d3.
      for (let i = 0; i < d1.length; i++) {
        if (!d3Set.has(JSON.stringify(d1[i]))) {
          rowsRemoved.push(i)
        }
      }
      return [d3, rowsRemoved]
    }
  }
  const previewTableColumns = useMemo(
    () =>
      previewTableHeaders.map((h) => ({
        Header:
          {
            asin: 'ASIN',
            type: 'Type',
            match_type: 'Match Type',
            target: 'Target',
            cost_of_goods_per_unit: 'Cost of Goods per Unit',
            shipping_cost_per_unit: 'Shipping Cost per Unit',
            misc_cost_per_unit: 'Misc Cost per Unit',
          }[h] || h,
        accessor: h,
        sortable: false,
        Cell: (props) =>
          h === 'match_type'
            ? ['keyword'].includes(props.row.original['type'])
              ? props.value.split(',').map((p) => (
                  <Badge key={p} className="mr-2" variant="outline">
                    {p}
                  </Badge>
                ))
              : ['category'].includes(props.row.original['type']) &&
                props.value?.includes('|')
              ? props.value
              : null
            : props.value,
      })),
    [previewTableHeaders]
  )

  return (
    <>
      <Card className="w-full lg:w-[700px]">
        <CardContent className="p-6 space-y-4">
          <CardHeader className="pt-0 pl-0 pb-0">
            <CardTitle className="font-space-grotesk font-normal">
              Import CSV
            </CardTitle>
            <p className="text-sm text-muted-foreground font-geist">
              See an example of a{' '}
              {importTypes?.map((t, i) => (
                <span key={i}>
                  <Link
                    to={{ pathname: t.exampleFileUrl }}
                    target="_blank"
                    className="text-primary hover:underline font-semibold"
                  >
                    {t.options.label.toLowerCase()}
                  </Link>
                  {importTypes?.length === i + 1 ? ' CSV file.' : ' or a '}
                </span>
              ))}
            </p>
          </CardHeader>
          <CardContent className="pl-0 pr-0 pb-0">
            <form onSubmit={importDataClick}>
              <div className="mb-4">
                <label
                  htmlFor="importType"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Import type
                </label>
                <Select
                  value={type?.value}
                  onValueChange={(value) => {
                    const selectedType = importTypes.find(
                      (t) => t.options.value === value
                    )?.options
                    inputFieldChange(
                      selectedType,
                      setType,
                      setTypeValidation,
                      'select_length',
                      1
                    )
                    handleTypeChange(selectedType)
                  }}
                >
                  <SelectTrigger id="importType">
                    <SelectValue placeholder="Select import type" />
                  </SelectTrigger>
                  <SelectContent>
                    {importTypes.map((t) => (
                      <SelectItem key={t.options.value} value={t.options.value}>
                        {t.options.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {typeValidation === 'error' && (
                  <p className="text-sm text-destructive mt-1">
                    This field is required.
                  </p>
                )}
              </div>

              {parsedData?.length > 0 ? (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Preview
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="px-0 ml-1"
                          >
                            <HelpCircle className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          Preview of first {previewRowCount} rows to be
                          imported.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </label>
                  <div className="py-3">
                    <FileQuestion className="inline mr-2" />
                    {importFile.name}
                    <Check className="inline mx-2 text-lime-500" />
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={() => setParsedData([])}
                    >
                      Replace
                    </Button>
                  </div>
                  <CustomTable
                    columns={previewTableColumns}
                    data={previewTableData}
                    loading={false}
                    total={previewRowCount}
                  />
                  {parsedErrors?.length > 0 && (
                    <Alert variant="warning" className="mt-2">
                      <AlertCircle className="h-4 w-4" />
                      <AlertDescription>
                        <ul className="list-disc pl-5">
                          {parsedErrors.map((error, idx) => (
                            <li key={idx}>
                              {error?.row
                                ? `Warning for row ${error?.row + 2} - ${
                                    error?.message
                                  }`
                                : error?.message}
                            </li>
                          ))}
                        </ul>
                      </AlertDescription>
                    </Alert>
                  )}
                </div>
              ) : (
                <div className="mb-4">
                  <label
                    htmlFor="csvFile"
                    className="flex text-sm font-medium text-gray-700 mb-1 gap-2"
                  >
                    CSV file
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <HelpCircle className="h-4 w-4 cursor-pointer translate-y-0.5" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <strong>file requirements</strong>
                          <br />
                          - be in CSV format
                          <br />
                          - not exceed 100MB in size
                          <br />- not contain more than 100 columns
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </label>
                  <UploadThumbnailGallery
                    assets={[]}
                    assetType={'csv'}
                    onPick={(e) => e.preventDefault()}
                    onUpload={onUpload}
                    uploading={isUploading}
                  />
                  {importFileValidation === 'has-danger' && (
                    <Alert variant="destructive" className="mt-2">
                      <AlertCircle className="h-4 w-4" />
                      <AlertDescription>
                        <ul className="list-disc pl-5">
                          {importValidationErrors.map((error, idx) => (
                            <li key={idx}>{error}</li>
                          ))}
                        </ul>
                      </AlertDescription>
                    </Alert>
                  )}
                </div>
              )}

              <div className="flex justify-start gap-4 mt-6">
                <Button
                  type="submit"
                  disabled={isImporting}
                  onClick={(e) => importDataClick(e)}
                >
                  {isImporting ? (
                    <LoadingSpinner className="w-4 h-4" />
                  ) : (
                    'Import Data'
                  )}
                </Button>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setImportType(null)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </CardContent>
      </Card>
    </>
  )
}

export default ProductsImport
