import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { inputFieldChange } from 'services/Utils'

import { Input } from 'shadcn-components/ui/input'
import { useToast } from 'shadcn-components/ui/use-toast'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'shadcn-components/ui/select'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'shadcn-components/ui/card'
import { Label } from 'shadcn-components/ui/label'
import {
  ShoppingCart,
  Globe2,
  AtSign,
  User,
} from 'lucide-react'

import apiMarketplaces from 'services/api/Marketplace'
import { useQuery, useQueryClient } from 'react-query'
import apiSellers from 'services/api/Seller'

const OnboardingSellerStep0 = forwardRef((props, ref) => {
  const params = useParams()
  let history = useHistory()
  const { toast } = useToast()
  const [email, setEmail] = useState(props.email)
  const [name, setName] = useState(props.name)
  const [region, setRegion] = useState(
    props.region === '' ? '' : { label: props.region, value: props.region }
  )
  const [marketplace, setMarketplace] = useState(
    props.marketplaceId === ''
      ? ''
      : { label: props.marketplaceCountry, value: props.marketplaceId }
  )
  const [marketplaceCountryCode, setMarketplaceCountryCode] = useState(
    props.marketplaceCountryCode
  )
  const [marketplaces, setMarketplaces] = useState([])
  const [publicId] = useState(props.publicId)
  const [companyPublicId] = useState(props.companyPublicId)
  const [managingCompanyPublicId] = useState(props.managingCompanyPublicId)

  const [emailFocus, setEmailFocus] = useState(false)
  const [nameFocus, setNameFocus] = useState(false)
  const [regionFocus, setRegionFocus] = useState(false)
  const [marketplaceFocus, setMarketplaceFocus] = useState(false)

  const [marketplaceDisabled, setMarketplaceDisabled] = useState(
    props.marketplaceId === '' ? true : false
  )

  const [emailValidationState, setEmailValidationState] = useState(
    props.email === '' ? '' : 'has-success'
  )
  const [nameValidationState, setNameValidationState] = useState(
    props.name === '' ? '' : 'has-success'
  )
  const [regionValidationState, setRegionValidationState] = useState(
    props.region === '' ? '' : 'has-success'
  )
  const [marketplaceValidationState, setMarketplaceValidationState] = useState(
    props.marketplaceId === '' ? '' : 'has-success'
  )

  const queryClient = useQueryClient()

  var marketplace_data = []
  var regions = []
  var region_marketplaces = []

  const { data } = useQuery(
    'GET/marketplaces/',
    () => apiMarketplaces.getAll(),
    {
      retry: false,
      staleTime: 604800000,
    }
  )

  if (data && data.status < 300) {
    marketplace_data = data.data.data
    regions = [...new Set(marketplace_data.map((q) => q.region))].map((x) => ({
      label: x,
      value: x,
    }))
    if (region !== '') {
      region_marketplaces = regionMarketplaces(region)
    }
  }

  function regionMarketplaces(region) {
    const filtered = marketplace_data.filter(function (m) {
      return m.region === region
    })
    return (region_marketplaces = [
      ...new Set(
        filtered.map((q) => ({ label: q.country, value: q.marketplace_id }))
      ),
    ])
  }

  const regionSelect = (region) => {
    region_marketplaces = regionMarketplaces(region)
    if (marketplace.value == '')
      setMarketplace({
        label: props.marketplaceCountry,
        value: props.marketplaceId,
      })
    setMarketplaceValidationState('')
    setMarketplaces(region_marketplaces)
    setMarketplaceDisabled(false)
  }

  const marketplaceOnFocus = (region) => {
    region_marketplaces = regionMarketplaces(region)
    setMarketplaces(region_marketplaces)
  }

  const marketplaceChange = (marketplace_id) => {
    const filtered = marketplace_data.filter(function (m) {
      return m.marketplace_id === marketplace_id
    })
    if (filtered.length > 0) {
      setMarketplaceCountryCode(filtered[0].country_code)
    }
  }

  const isValidated = async () => {
    if (
      nameValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      regionValidationState === 'has-success' &&
      marketplaceValidationState === 'has-success'
    ) {
      const seller = {
        company_public_id: companyPublicId,
        ...(managingCompanyPublicId && {
          managing_company_public_id: managingCompanyPublicId,
        }),
        public_id: params.public_id ? params.public_id : publicId,
        name: name,
        email: email,
        marketplace_region: region.value,
        primary_marketplace_id: marketplace.value,
      }
      // create/update seller via api
      const response = await apiSellers
        .post(JSON.stringify(seller))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/auth/user') // force a refetch to ensure correct onboarding redirect behaviour and updated primary seller in navbar
        toast({
          variant: 'success',
          description: response?.data?.message,
        })
        history.push('/admin/onboarding/seller/' + response.data.public_id)
        return true
      } else {
        if (response) {
          toast({
            variant: 'destructive',
            title: 'Oops!',
            description: response?.data?.message,
          })
        }
        return false
      }
    } else {
      if (nameValidationState !== 'has-success') {
        setNameValidationState('has-danger')
      }
      if (emailValidationState !== 'has-success') {
        setEmailValidationState('has-danger')
      }
      if (regionValidationState !== 'has-success') {
        setRegionValidationState('has-danger')
      }
      if (marketplaceValidationState !== 'has-success') {
        setMarketplaceValidationState('has-danger')
      }
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
  }))

  // Set initial email, name, and region states
  useEffect(() => {
    setEmail(props.email)
    setName(props.name)
    setRegion(
      props.region === '' ? '' : { label: props.region, value: props.region }
    )
    setMarketplace({
      label: props.marketplaceCountry,
      value: props.marketplaceId,
    })
    if (props.email != '' && props.name != '') {
      setEmailValidationState('has-success')
      setNameValidationState('has-success')
      setRegionValidationState('has-success')
    }
  }, [props.email, props.name, props.region, props.marketplaceId])

  // Populate marketplaces based on initial region
  useEffect(() => {
    if (region.value) {
      regionSelect(region.value)
    }
  }, [region.value])

  // Set initial marketplace state
  useEffect(() => {
    if (props.region && marketplace_data.length > 0) {
      if (region?.value !== props.region) {
        setRegion({ label: props.region, value: props.region })
        setRegionValidationState('has-success')
      }

      const marketplaces = regionMarketplaces(props.region)
      setMarketplaces(marketplaces)
      setMarketplaceDisabled(false)

      if (props.marketplaceId && props.marketplaceCountry) {
        setMarketplace({
          label: props.marketplaceCountry,
          value: props.marketplaceId,
        })
        setMarketplaceValidationState('has-success')
      }
    }
  }, [props.region, marketplace_data])

  return (
    <>
      <CardHeader className="px-0 sm:px-6">
        <CardTitle className="text-xl text-center font-space-grotesk font-normal">
          Let's start with your seller account details.
        </CardTitle>
      </CardHeader>
      <CardContent className="px-0 sm:px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Seller Account Name</Label>
              <div className="relative">
                <User className="absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                <Input
                  id="name"
                  value={name}
                  onChange={(e) =>
                    inputFieldChange(
                      e,
                      setName,
                      setNameValidationState,
                      'length',
                      1
                    )
                  }
                  className={`pl-10 ${
                    nameValidationState === 'invalid' ? 'border-red-400' : ''
                  }`}
                />
              </div>
              {nameValidationState === 'invalid' && (
                <p className="text-red-400 text-sm">This field is required.</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Seller Central Email</Label>
              <div className="relative">
                <AtSign className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) =>
                    inputFieldChange(
                      e,
                      setEmail,
                      setEmailValidationState,
                      'email'
                    )
                  }
                  className={`pl-10 ${
                    emailValidationState === 'invalid' ? 'border-red-400' : ''
                  }`}
                />
              </div>
              {emailValidationState === 'invalid' && (
                <p className="text-red-400 text-sm">This field is required.</p>
              )}
            </div>
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="region">Region</Label>
              <div className="relative">
                <Globe2 className="absolute left-2 top-3 h-4 w-4 text-muted-foreground z-10" />
                <Select
                  value={region?.value}
                  onValueChange={(value) => {
                    const selectedOption = regions.find(
                      (option) => option.value === value
                    )
                    inputFieldChange(
                      selectedOption,
                      setRegion,
                      setRegionValidationState,
                      'select_length',
                      1
                    )
                    regionSelect(value)
                  }}
                >
                  <SelectTrigger className="pl-8">
                    <SelectValue placeholder="Select a region" />
                  </SelectTrigger>
                  <SelectContent>
                    {regions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {regionValidationState === 'invalid' && (
                <p className="text-red-400 text-sm">This field is required.</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="marketplace">Primary Marketplace</Label>
              <div className="relative">
                <ShoppingCart className="absolute left-2 top-3 h-4 w-4 text-muted-foreground z-10" />
                <Select
                  value={marketplace?.value}
                  onValueChange={(value) => {
                    const selectedOption = marketplaces.find(
                      (option) => option.value === value
                    )
                    inputFieldChange(
                      selectedOption,
                      setMarketplace,
                      setMarketplaceValidationState,
                      'select_length',
                      1
                    )
                    marketplaceChange(value)
                  }}
                  disabled={marketplaceDisabled}
                >
                  <SelectTrigger className="pl-8">
                    <SelectValue placeholder="Primary marketplace..." />
                  </SelectTrigger>
                  <SelectContent>
                    {marketplaces.map((m) => (
                      <SelectItem key={m.value} value={m.value}>
                        {m.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {marketplaceValidationState === 'invalid' && (
                <p className="text-red-400 text-sm">This field is required.</p>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </>
  )
})

export default OnboardingSellerStep0
