import React, { useEffect, useState, useContext } from 'react'
// reactstrap components
import { AlertCircle } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from 'shadcn-components/ui/alert'
import { Card, CardContent } from 'shadcn-components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'shadcn-components/ui/tabs'

import { useQuery, useQueryClient } from 'react-query'
import { Link, useLocation, useHistory } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import Goal from './Goal'
import GoalsByProduct from './GoalsByProduct'
import { sellerProfileId, freeUser } from 'services/Utils'
import { useToast } from 'shadcn-components/ui/use-toast'
import { Skeleton } from 'shadcn-components/ui/skeleton'
import ThemeContext from 'services/providers/ThemeContext'

const Goals = ({ user }) => {
  const { lightMode } = useContext(ThemeContext)
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const pathParts = location.pathname.split('/')
  const goalId = pathParts[pathParts.length - 1]

  const objectives = [
    { value: 'maximize_sales', label: 'Maximize Sales' },
    { value: 'maximize_profit', label: 'Maximize Profit' },
  ]

  const strategies = [
    // {
    //   value: 'minimum_net_profit',
    //   label: 'Target Profit',
    //   value_format: (value) => {
    //     return Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 1,
    //       maximumFractionDigits: 1 }).format(value)
    //   }
    // },
    {
      value: 'maximum_acos',
      label: 'Target Advertising Cost of Sales (ACOS)',
      value_format: (value) => {
        return Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(value)
      },
    },
    // {
    //   value: 'maximum_daily_budget',
    //   label: 'Target Daily Budget',
    //   value_format: (value, cur) => {
    //     return Intl.NumberFormat('en-US', { style: 'currency', currency: cur || 'USD' }).format(value)
    //   }
    // }
    {
      value: 'maximum_tacos',
      label: 'Target Total Advertising Cost of Sales (TACOS)',
      value_format: (value) => {
        return Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(value)
      },
    },
  ]

  const [horizontalTabs, setHorizontalTabs] = useState(() => {
    if (goalId && goalId !== 'goals') {
      return 'byProduct'
    }
    return queryParams.get('tab') || 'global'
  })
  const [primarySeller, setPrimarySeller] = useState(user.primary_seller || {})
  const primarySellerPublicId = primarySeller?.public_id
  const [globalGoal, setGlobalGoal] = useState()
  const [productGoals, setProductGoals] = useState([])
  const [products, setProducts] = useState([])
  const [newProducts, setNewProducts] = useState([])
  const [profileId, setProfileId] = useState(
    sellerProfileId(user.primary_seller)
  )
  const [isFreeUser, setIsFreeUser] = useState(freeUser(user))
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: freeUser(user) ? 5000 : 10,
  })

  const [gfilterQuery, setGfilterQuery] = useState(false)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])

  const [globalGoalOn, setGlobalGoalOn] = useState(true)
  const [productGoalsOn, setProductGoalsOn] = useState(true)
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const setGoalStates = (gObj, param) => {
    const gGoal = gObj?.data.goals?.find((goal) => goal.type === 'global')
    const prodGoals = []
    setProducts(
      gObj?.data.goals
        .filter((goal) => goal?.type === 'product')
        .map((goal) => {
          prodGoals.push(goal)
          return {
            public_id: goal.product_public_id,
            asin: goal.product_asin,
            sku: goal.product_sku,
            your_price: goal.product_your_price,
            price: goal.product_price,
            profit: goal.product_profit,
          }
        })
    )

    setGlobalGoal(gGoal)
    setProductGoals(prodGoals)
    setPages(gObj?.data.pages)
    setTotal(gObj?.data.total)
    setLoading(false)
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
    setGlobalGoalOn(gGoal?.state === 'enabled')
    setProductGoalsOn(prodGoals?.some((g) => g.state === 'enabled'))
  }

  const {
    data: goalsObject,
    isFetching: goalsIsLoading,
    goalsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/goals', queryParam],
    () =>
      apiSellers.goals(
        primarySellerPublicId,
        Object.assign(queryParam, {
          'filter[type][in]': JSON.stringify(['global', 'product']),
        })
      ),
    {
      // filter hack... improve!
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (goalsObject) => {
        setGoalStates(goalsObject, queryParam)
      },
    }
  )

  useEffect(() => {
    const goalsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/goals',
      queryParam,
    ])
    if (goalsCache && goalsCache.data?.goals) {
      setGoalStates(goalsCache, queryParam)
    }
  }, [queryClient, primarySellerPublicId, queryParam])
  useEffect(() => {
    const tab = queryParams.get('tab')
    const pathParts = location.pathname.split('/')
    const currentGoalId = pathParts[pathParts.length - 1]

    if (currentGoalId && currentGoalId !== 'goals') {
      setHorizontalTabs('byProduct')
    } else if (tab && tab !== horizontalTabs) {
      setHorizontalTabs(tab)
    }
  }, [location.pathname, location.search])

  useQuery(
    ['GET/sellers/', primarySellerPublicId, '/goals-new'],
    () => apiSellers.newGoals(primarySellerPublicId),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (newGoalsObj) => {
        setNewProducts(newGoalsObj?.data?.new_product_goals)
      },
    }
  )

  useEffect(() => {
    const newGoalsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/goals-new',
    ])
    if (newGoalsCache && newGoalsCache.data) {
      setNewProducts(newGoalsCache?.data?.new_product_goals)
    }
  }, [queryClient, primarySellerPublicId])

  const handleTabChange = (value) => {
    setHorizontalTabs(value)
    if (!goalId || goalId === 'goals') {
      const newParams = new URLSearchParams(location.search)
      newParams.set('tab', value)
      history.replace({ search: newParams.toString() })
    }
  }

  return (
    <>
      <div className="py-8 px-2 overflow-hidden w-[96%] mx-auto">
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-col gap-1 mb-6">
            <h2
              className={`text-3xl tracking-normal font-space-grotesk ${
                lightMode ? 'text-slate-800' : 'text-white'
              }`}
            >
              Goals
            </h2>
            <p className="text-muted-foreground font-geist">
              What are your advertising goals? Select the settings that would
              make advertising successful for you.
              <br />
              Autron's AI advertising engine optimizes performance towards your
              objective given the strategic constraints.
            </p>
          </div>
          {loading ? (
            <Card className="overflow-visible h-auto w-full lg:w-[700px]">
              <CardContent className="pt-6 overflow-visible">
                <div className="space-y-4 py-6">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-4 w-1/2" />
                  <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                  <div className="grid grid-cols-3 gap-4 pt-4 w-full">
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-40 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <>
              {products?.length > 0 || newProducts?.length || gfilterQuery ? (
                <Tabs
                  value={horizontalTabs}
                  onValueChange={handleTabChange}
                  className="w-full"
                >
                  {globalGoal &&
                    !gfilterQuery &&
                    (!globalGoalOn ||
                      (!productGoalsOn &&
                        queryParam.page === 1 &&
                        !newProducts.length)) && (
                      <>
                        <Alert variant="destructive" className="-mt-2 mb-2">
                          <AlertCircle className="h-4 w-4" />
                          <AlertTitle>Warning</AlertTitle>
                          <AlertDescription>
                            {!globalGoalOn
                              ? "Autron's AI is not managing your ads. Click 'Relaunch Autron!' to continue."
                              : 'All your product goals are off. Turn one back on to benefit from Autron AI ad engine managing your ads.'}
                          </AlertDescription>
                        </Alert>
                      </>
                    )}
                  <TabsList className="w-[200px]">
                    <TabsTrigger value="global" className="w-1/2">
                      Global
                    </TabsTrigger>
                    <TabsTrigger value="byProduct" className="w-1/2">
                      By Product
                    </TabsTrigger>
                  </TabsList>

                  <TabsContent value="global">
                    <Goal
                      objectives={objectives}
                      strategies={strategies}
                      type={'global'}
                      goal={globalGoal}
                      goalGlobalPublicId={globalGoal?.public_id}
                      goalGlobalASIN={
                        isFreeUser
                          ? productGoals?.find(
                              (pg) =>
                                pg.use_global_public_id !== null &&
                                pg.state === 'enabled'
                            )?.product_asin
                          : null
                      }
                      products={products}
                      newProducts={newProducts}
                      seller={primarySeller}
                      profileId={profileId}
                      isFreeUser={isFreeUser}
                      dataIsLoading={false}
                      activeTab={horizontalTabs}
                    />
                  </TabsContent>

                  <TabsContent value="byProduct">
                    {globalGoalOn ? (
                      <GoalsByProduct
                        objectives={objectives}
                        strategies={strategies}
                        type={'product'}
                        goals={productGoals}
                        globalGoal={globalGoal}
                        newProducts={newProducts}
                        seller={primarySeller}
                        profileId={profileId}
                        isFreeUser={isFreeUser}
                        loading={goalsIsLoading}
                        total={total}
                        pageCount={pages}
                        queryParam={queryParam}
                        setQueryParam={setQueryParam}
                        gfilterQuery={gfilterQuery}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        activeTab={horizontalTabs}
                      />
                    ) : (
                      <>
                        <Alert>
                          <AlertDescription>
                            Create and turn on a global goal before managing
                            product specific goals.
                          </AlertDescription>
                        </Alert>
                      </>
                    )}
                  </TabsContent>
                </Tabs>
              ) : (
                <>
                  <Alert className="mt-4">
                    <AlertDescription>
                      Looks like your{' '}
                      <Link
                        to="/admin/products"
                        className="font-medium underline"
                      >
                        Products
                      </Link>{' '}
                      haven't synced yet. Once your products are ready you can
                      set your goals.
                    </AlertDescription>
                  </Alert>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Goals
