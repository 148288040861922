import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardTitle,
  CardDescription,
} from 'shadcn-components/ui/card'
import { Button } from 'shadcn-components/ui/button'
import { Badge } from 'shadcn-components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'shadcn-components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'shadcn-components/ui/tooltip'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'shadcn-components/ui/dialog'

import {
  MoreHorizontal,
  Pencil,
  Trash2,
  AlertCircle,
  ExternalLink,
} from 'lucide-react'

import CustomTable from 'components/CustomTable/CustomTable'
import VideoMedia from './VideoMedia'

const VideoCreatives = ({
  brands,
  products,
  failedSpec,
  mediaUpdate,
  uploadingMedia,
  updatingMedia,
  failedSpecUpdate,
  productsDataObject,
}) => {
  const history = useHistory()
  const params = useParams()

  const [video, setVideo] = useState()
  const [videoMeta, setVideoMeta] = useState({})
  const [allVideoData, setAllVideoData] = useState([])
  const [showVideoForm, setShowVideoForm] = useState(false)
  const [actionsCellWidth, setActionsCellWidth] = useState(0)

  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 5,
  })

  const [archiveVideoWarningAlert, setArchiveVideoWarningAlert] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    setShowVideoForm(params.public_id ? true : false)
    if (params.public_id !== 'create') {
      setVideo(allVideoData.find((v) => v.public_id === params.public_id))
    }
  }, [params, allVideoData])

  const actionsCellRef = useCallback((domNode) => {
    if (domNode) {
      setActionsCellWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const productsCell = (asins) => {
    const displayNProducts = 3
    const allASINs = products.map((p) => p.value)
    const intersection = allASINs.filter((e) => asins.includes(e))
    if (intersection.length === allASINs.length) {
      return ['all products']
    } else {
      if (intersection.length > displayNProducts) {
        return intersection
          .slice(0, displayNProducts)
          .concat([
            '+ ' +
              (intersection.length - displayNProducts).toString() +
              ' more',
          ])
      } else {
        return intersection
      }
    }
  }

  const archiveVideoWarning = (video) => {
    setArchiveVideoWarningAlert(video)
  }
  const archiveVideoWarningConfirm = (video) => {
    // archive video via api
    mediaUpdate({
      brand_entity_id: video?.brand_id,
      action: 'update_brand_media',
      media_id: video?.media_id,
      creative_asset_id: video?.creative_asset_id,
      state: 'archived',
    })
    setTableLoading(true)
    setArchiveVideoWarningAlert(null)
  }

  const editVideoClick = (row) => {
    if (videoData) {
      setVideo(videoData.find((v) => v.media_id === row.original['media_id']))
      history.push('/admin/creatives/video/' + row.original['public_id'])
    }
  }

  const videoCols = useMemo(
    () => [
      {
        Header: 'Video',
        accessor: 'published_media_url',
        Cell: (props) => (
          <div className="w-72 h-44 relative">
            {props.row.original['filename'].slice(-4) === '.mov' ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500 text-center">
                No preview available for .mov
              </div>
            ) : props.row.original['status'] !== 'Available' ? (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500 text-center rounded">
                Preview available once approved
              </div>
            ) : (
              <video
                controls
                muted
                src={props.value}
                className="w-full h-full object-cover rounded"
                controlsList="nodownload"
                onLoadedMetadata={(e) => {
                  if (videoMeta.videoHeight === undefined) {
                    setVideoMeta({
                      videoHeight: e.target.videoHeight,
                      videoWidth: e.target.videoWidth,
                      duration: e.target.duration,
                    })
                  }
                }}
              />
            )}
          </div>
        ),
      },
      {
        Header: 'Filename',
        accessor: 'filename',
        Cell: (props) => (
          <div>
            <div>{props.value}</div>
            {videoMeta.videoHeight && (
              <div className="text-sm text-gray-500">
                {videoMeta.videoHeight}p ·{' '}
                {new Date(videoMeta.duration * 1000)
                  .toISOString()
                  .substring(15, 19)}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Amazon Approved',
        accessor: 'status',
        Cell: (props) => (
          <div className="flex items-center">
            <Badge
              variant="outline"
              className={`capitalize ${
                props.value === 'Available'
                  ? 'border-lime-300 bg-lime-50 text-lime-400 dark:border-lime-200 dark:bg-transparent dark:text-lime-300'
                  : props.value === 'Failed'
                  ? 'border-red-300 bg-red-50 text-red-400 dark:border-red-200 dark:bg-transparent dark:text-red-300'
                  : 'border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200'
              }`}
            >
              {props.value === 'Available'
                ? 'Approved'
                : props.value === 'Failed'
                ? 'Failed'
                : 'Pending'}
            </Badge>
            {props.value === 'Failed' && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <AlertCircle className="h-4 w-4 ml-2" />
                  </TooltipTrigger>
                  <TooltipContent>
                    Failure reason: {props.row.original.status_meta_code}{' '}
                    {props.row.original.status_meta_message}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        ),
      },
      {
        Header: 'Brand',
        accessor: 'brand',
      },
      {
        Header: 'Associated products',
        accessor: 'product_asins',
        Cell: (props) => (
          <div>
            {productsCell(props.value)?.map((p, idx) => (
              <Badge
                key={idx}
                variant="outline"
                className="border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200 mr-1"
              >
                {p}
              </Badge>
            ))}
          </div>
        ),
      },
      {
        Header: 'Actions',
        Cell: (props) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => editVideoClick(props.row)}>
                <Pencil className="h-4 w-4 mr-2" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => archiveVideoWarning(props.row.original)}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Archive
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    [allVideoData, videoMeta, products]
  )

  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size)
  }

  const videoData = useMemo(() => {
    setTotal(allVideoData.length)
    setPageCount(Math.ceil(allVideoData?.length / queryParam.per_page))
    return paginate(allVideoData, queryParam.per_page, queryParam.page)
  }, [allVideoData, queryParam])

  useEffect(() => {
    if (brands) {
      const videos = brands.reduce(
        (results, brand) => [
          ...results,
          ...brand.media.map((m) => ({
            public_id: m.public_id,
            media_id: m.media_id,
            creative_asset_id: m.creative_asset_id,
            published_media_url: m.published_media_url,
            filename: m.filename,
            brand_id: brand.brand_entity_id,
            brand: brand.brand_registry_name,
            product_asins: m.product_asins,
            status: m.status,
            status_meta_code: m.status_meta_code,
            status_meta_message: m.status_meta_message,
          })),
        ],
        []
      )
      setAllVideoData(videos)
    }
  }, [brands])

  return (
    <>
      {archiveVideoWarningAlert ? (
        <>
          <Dialog
            open={archiveVideoWarningAlert ? true : false}
            onOpenChange={() => setArchiveVideoWarningAlert(null)}
          >
            <DialogContent className="w-[380px] rounded-lg sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle className="text-xl font-space-grotesk font-normal flex items-center gap-2">
                  Are you sure?
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="mb-4">
                <p className="font-regular">
                  Archiving video{' '}
                  <span className="font-bold">
                    {archiveVideoWarningAlert?.filename}
                  </span>{' '}
                  cannot be undone. All ads using this video will be stopped.
                </p>
              </DialogDescription>
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setArchiveVideoWarningAlert(null)}
                  className="mt-4 sm:mt-0"
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={() =>
                    archiveVideoWarningConfirm(archiveVideoWarningAlert)
                  }
                >
                  Yes, archive it!
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <></>
      )}
      <Card className="w-full">
        <CardContent className="px-6 py-4">
          <div className="mb-6">
            <CardTitle className="text-2xl font-normal font-space-grotesk tracking-normal">
              Video
            </CardTitle>
            <CardDescription className="font-normal">
              Keep your videos brief and relevant. They will autoplay, so make
              sure the first 2 seconds are highly engaging!{' '}
              <a
                href="https://advertising.amazon.com/resources/ad-policy/sponsored-ads-policies#sbv"
                target="_blank"
                rel="noopener noreferrer"
                className="p-0 inline underline hover:text-slate-600"
              >
                See video approval tips
                <ExternalLink className="ml-1 h-4 w-4 -translate-y-[.10rem] inline" />
              </a>
            </CardDescription>
          </div>
          {showVideoForm ? (
            <VideoMedia
              brands={brands}
              products={products}
              failedSpec={failedSpec}
              video={video}
              mediaUpdate={mediaUpdate}
              uploadingMedia={uploadingMedia}
              updatingMedia={updatingMedia}
              failedSpecUpdate={failedSpecUpdate}
              productsDataObject={productsDataObject}
            />
          ) : (
            <>
              <Button
                className="mb-6"
                onClick={() => {
                  setVideo({})
                  history.push('/admin/creatives/video/create')
                }}
              >
                Add Video
              </Button>
              {videoData.length > 0 && (
                <CustomTable
                  columns={videoCols}
                  data={videoData}
                  loading={tableLoading}
                  total={total}
                  pageCount={pageCount}
                  queryParam={queryParam}
                  setQueryParam={setQueryParam}
                  onRowClick={null}
                  showGlobalSearch={false}
                />
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default VideoCreatives
