import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Badge } from 'shadcn-components/ui/badge'
import { ChevronDown, Search, X } from 'lucide-react'

const MultiSelect = ({
  options,
  selectedOptions,
  onOptionSelect,
  onOptionUnselect,
  placeholder = 'Select...',
  filteredOptions: customFilteredOptions,
}) => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const dropdownRef = useRef(null)
  const multiSelectRef = useRef(null)

  const handleInputClick = () => setOpen((prev) => !prev)

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      multiSelectRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !multiSelectRef.current.contains(event.target)
    ) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // Get filtered options based on whether customFilteredOptions is a function or not
  const getFilteredOptions = useCallback(() => {
    if (typeof customFilteredOptions === 'function') {
      const results = customFilteredOptions(inputValue)
      return Array.isArray(results) ? results : []
    }

    return options.filter(
      (option) =>
        !selectedOptions.some((p) => p.value === option.value) &&
        option.label.toLowerCase().includes(inputValue.toLowerCase())
    )
  }, [customFilteredOptions, options, selectedOptions, inputValue])

  const filteredOptions = getFilteredOptions()

  return (
    <div className="relative">
      <div
        ref={multiSelectRef}
        onClick={handleInputClick}
        className="relative flex flex-wrap gap-1 rounded-md border border-input px-3 py-2 text-sm min-h-10 pr-9"
      >
        {selectedOptions.map((option, index) => (
          <Badge
            key={option.value + index + option.label}
            variant="outline"
            className="border-yellow-300 bg-yellow-50 text-yellow-400 dark:border-yellow-100 dark:bg-transparent dark:text-yellow-200"
          >
            {option.label}
            <button
              type="button"
              className="ml-1 rounded-full"
              onClick={(e) => {
                e.stopPropagation()
                onOptionUnselect(option)
              }}
            >
              <X className="h-3 w-3 text-yellow-400 hover:text-yellow-500 dark:text-yellow-200 dark:hover:text-yellow-300" />
            </button>
          </Badge>
        ))}
        <ChevronDown className="w-4 h-4 opacity-50 absolute right-3 top-3" />
      </div>

      {open && (
        <div
          ref={dropdownRef}
          className={`absolute z-10 top-full ${
            filteredOptions.length > 4 ? 'h-40' : 'h-auto'
          } overflow-y-scroll w-full mt-1 rounded-md border bg-popover text-popover-foreground shadow-md`}
        >
          <div className="flex items-center border-b px-3 w-full">
            <Search className="mr-2 h-4 w-4 opacity-50" />
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={placeholder}
              className="flex h-9 bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground w-full"
              style={{ boxShadow: 'none' }}
            />
          </div>
          <div className="px-1 py-1">
            {filteredOptions.map((option, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  onOptionSelect(option)
                }}
                className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
              >
                {option.label}
              </button>
            ))}
          </div>

          {filteredOptions.length === 0 && (
            <div className="px-2 py-2 text-sm text-muted-foreground">
              No options
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MultiSelect
